import React, {useContext, useState} from 'react'
import {Link} from 'react-router-dom'
import LETTER_COLORS from '../../constants/LetterColors'
import {ProfileContext} from '../../contexts/profilecontext'

type Props = {
  profile: any
  styleClasses?: {
    profilePic?: string
    letterWrap?: string
    nameLetter?: string
  }
  privacyType?: string
  isReply?: boolean
}

const UserPicture = ({profile, styleClasses, privacyType = 'public', isReply = false}: Props) => {
  const [imgError, setImgError] = useState(false)
  if (!profile) return <></>
  else if (privacyType === 'anonymous') {
    return (
      <>
        <img
          style={isReply ? {width: 40, height: 40, marginRight: 0} : {}}
          className={styleClasses?.profilePic ? styleClasses?.profilePic : 'feed__barProfileImg'}
          alt='profile'
          src={`https://avatars.dicebear.com/api/bottts/secret.svg`}
        />
        <p className='fs-10'> Anonymous</p>
      </>
    )
  } else if ((!profile.profile_picture || imgError) && profile.full_name) {
    return (
      <div
        className={styleClasses?.letterWrap ? styleClasses.letterWrap : 'navbar__barNameLetterWrap'}
        style={
          isReply
            ? {width: 40, height: 40, marginRight: 0}
            : {
                background: LETTER_COLORS[profile.first_name.charAt(0).toUpperCase()].background,
              }
        }
      >
        <span
          className={styleClasses?.nameLetter ? styleClasses.nameLetter : 'navbar__barNameLetter'}
          style={{
            color: LETTER_COLORS[profile.first_name.charAt(0).toUpperCase()].color,
          }}
        >
          {profile.first_name.charAt(0).toUpperCase()}
        </span>
      </div>
    )
  } else if (profile.profile_picture && profile.full_name && !imgError) {
    return (
      <img
        style={isReply ? {width: 40, height: 40, marginRight: 0} : {}}
        alt='profile'
        className={styleClasses?.profilePic ? styleClasses.profilePic : 'feed__barProfileImg'}
        src={profile.profile_picture}
        onError={({currentTarget}) => {
          currentTarget.onerror = null // prevents looping
          setImgError(true)
        }}
      />
    )
  }
  return <></>
}

export default UserPicture
