/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useContext, useCallback} from 'react'
// import {IProfileDetails, profileDetailsInitValues as initialValues} from '../../../modules/profile/components/settings/SettingsModel'
import {useHistory} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'

import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../_metronic/helpers'
import {PasswordMeterComponent} from '../../_metronic/assets/ts/components'

import Modal from 'react-modal'

import {UserContext} from '../contexts/usercontext'

import FeatherIcon from 'feather-icons-react'
import TextField from '../modules/auth/reusable/TextField'

import POLITICIAN_SERIVCE from '../services/politician/PoliticianService'
import {ProfileContext} from '../contexts/profilecontext'
import AUTH_SERVICE from '../services/auth/AuthService'

import VOTER_SERVICE from '../services/voter/VoterService'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'

import './RightSideBar.scss'

import OPEN_WEATHER_CONFIG from '../openWeatherConfig.json'
import WeatherIcon from 'react-open-weather-icons'

import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
  getCity,
} from 'react-places-autocomplete'
import {FeedLocationContext} from '../contexts/feedLocationContext'

import dateFormat, {masks} from 'dateformat'
const now = new Date()

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '12px',
  },
}

const initialValues = {
  newEmail: '',
}

const emailSchema = Yup.object().shape({
  newEmail: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
})

export function RightSideBar(props) {
  const PROFILE_CONTEXT = useContext(ProfileContext)
  const [weatherInfo, setWeatherInfo] = useState({
    main: {temp: 0, feels_like: 0},
    weather: [{icon: ''}],
  })

  const {feedLocation} = useContext(FeedLocationContext)

  const getCurrentWeather = async (coordinates) => {
    try {
      let response = await fetch(
        `https://api.openweathermap.org/data/2.5/weather?lat=${coordinates.lat}&lon=${coordinates.lng}&appid=${OPEN_WEATHER_CONFIG.key}&units=metric`
      )

      let result = await response.json()
      setWeatherInfo(result)
      console.log(result)
    } catch (error) {
      console.log(error)
    }
  }

  const getCoordinatesProfile = async () => {
    try {
      let address =
        PROFILE_CONTEXT.profile.location_city +
        ', ' +
        PROFILE_CONTEXT.profile.location_region +
        ', ' +
        PROFILE_CONTEXT.profile.postal_code

      const results = await geocodeByAddress(address)
      const latLng = await getLatLng(results[0])

      getCurrentWeather(latLng)

      console.log(latLng)
    } catch (error) {
      console.log(error)
    }
  }

  const getCoordinatesFeedLocation = async () => {
    try {
      let address = feedLocation.city + ', ' + feedLocation.region
      const results = await geocodeByAddress(address)
      const latLng = await getLatLng(results[0])

      getCurrentWeather(latLng)

    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (
      feedLocation.city &&
      feedLocation.region &&
      (feedLocation.city !== PROFILE_CONTEXT.profile.location_city ||
        feedLocation.region !== PROFILE_CONTEXT.profile.location_region)
    ) {
      getCoordinatesFeedLocation()
    } else if (PROFILE_CONTEXT.profile.coords) {
      getCurrentWeather(PROFILE_CONTEXT.profile.coords)
    } else if (PROFILE_CONTEXT.profile.location_city && PROFILE_CONTEXT.profile.location_region) {
      getCoordinatesProfile()
    }
  }, [feedLocation, PROFILE_CONTEXT.profile.location_city])

  return (
    <div className='RightSideBar hide-mobile'>
      {feedLocation.city && (
        <div className='rightSideBar__weather'>
          <div className=' '>
            <h3 className='rightSideBar__weatherLocation'>
              {feedLocation.city}
            </h3>
            <small className='rightSideBar__weatherDate'>
              {dateFormat(now, 'dddd, mmmm dS, yyyy')}
            </small>
          </div>

          <div className='civik-flex-column'>
            <hr></hr>
            <span className='rightSideBar__weatherLabel'>TEMPERATURE</span>
          </div>

          <div className='rightSideBar__weatherInfoContainer civik-flex-row'>
            <div className='civik-flex-column'>
              <h1 className='rightSideBar__weatherTemperature'>
                {Math.trunc(weatherInfo.main.temp)}
              </h1>
              <small className='rightSideBar__weatherFeelsLike'>
                Feels like: {Math.ceil(weatherInfo.main.feels_like)}
              </small>
            </div>

            <WeatherIcon name={weatherInfo.weather[0].icon} className='rightSideBar__weatherIcon' />
          </div>
        </div>
      )}

      <div></div>
    </div>
  )
}
