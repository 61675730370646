import React, { Component } from 'react'
import clsx from 'clsx'

import './TextAreaField.scss'

class TextAreaField extends Component {
  constructor(props) {
    super(props)
  }


  render() {
    const { formik, type, field, placeholder, label, isRequired, noLabel } = this.props

    return (
      <div>
        {!noLabel &&
          <label className={isRequired ? 'form-label fs-6 fw-bold text-dark required' : 'form-label fs-6 fw-bold text-dark'}>{label}</label>
        }

        <textarea
          rows="4" cols="50"
          placeholder={placeholder}
          {...formik.getFieldProps(field)}
          className={clsx(
            'TextAreaField',
            { 'is-invalid': formik.touched[field] && formik.errors[field] },
            {
              'is-valid': formik.touched[field] && !formik.errors[field],
            }
          )}
          type='text'
          name={field}
          autoComplete='off'
          ref={this.props.inputRef}
        />
        {formik.touched[field] && formik.errors[field] && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors[field]}</span>
          </div>
        )}
      </div>
    )
  }
}


export default TextAreaField
