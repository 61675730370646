import civikApi from '../../../utils/civikAPI'

const AdminService = {
  approvePendingProfile: async (politician_id) => {
    try {
      // update pending profile
      await civikApi.put(`/admin/politicians/approval/${politician_id}`)
      return {code: 200, message: 'approved'}
    } catch (error) {
      console.log(error)
      // return ERROR_CODES.firebaseErrors[error.code]
    }
  },
  getVoterPins: async (voterlist) => {
    try {
      const result = await civikApi.post(`/voterlist/getvoterpin`, voterlist, {
        responseType: 'blob',
      })
      return {success: true, data: result}
    } catch (error) {
      console.log(error)
    }
  },
  sendVoterPins: async (voterlist) => {
    try {
      const result = await civikApi.post(`/voterlist/sendvoterpinemail`, voterlist, {
        responseType: 'blob',
      })
      return {success: true, data: result}
    } catch (error) {
      console.log(error)
    }
  },
  getVoterPinFileUploadHistory: async () => {
    try {
      const result = await civikApi.get(`/voterlist/uploadhistory`)
      return {success: true, data: result}
    } catch (error) {
      console.log(error)
    }
  },
}

export default AdminService
