// NOTIFICATION TYPES
//   POLL_VOTE: 1,
//   PETITION_VOTE: 2,
//   ARTICLE_VOTE: 3,
//   DISCUSSION_VOTE: 4,
//   POLL_COMMENT: 5,
//   PETITION_COMMENT: 6,
//   NEWS_COMMENT: 7,
//   DISCUSSION_COMMENT: 8,
//   SIGNED_PETITION: 9,
//   POL_PLATFORM_COMMENT: 10,
//   POL_PLATFORM_VOTE: 11,
//   COMMENT_VOTE: 12,
//   COMMENT_REP: 13,
//   COMMENT_COMMENT: 14,
//   VOLUNTEER: 15,
//   DELIVER_PETITION: 16,
//   FOLLOW: 17,
//   BASIC_VOTE: 18,
//   BASIC_COMMENT: 19,
// APPROVE_PROFILE: 20,

import ROUTE_PATHS from "../routing/RoutePaths"

export const getUserName = (notification) => {
  if (notification.latest_interactor_anonymous_username) {
    return notification.latest_interactor_anonymous_username
  } else if (notification.latest_interactor) {
    return (
      notification.latest_interactor.first_name + ' ' + notification.latest_interactor.last_name
    )
  } else if (notification.notification_type === 20) {
    return 'An admin'
  }
  return 'A user'
}

export const getOthers = (notification) => {
  var others = -1
  var {interactors_anonymous, interactors} = notification
  interactors = interactors.filter((i) => i !== null)
  interactors_anonymous = interactors_anonymous.filter((i) => i !== null)
  if (interactors_anonymous && interactors_anonymous.length > 0) {
    others += interactors_anonymous.length
  }
  if (interactors && interactors.length > 0) {
    others += interactors.length
  }
  if (others > 0) {
    if (others === 1) {
      return ` and ${others} other`
    }
    return ` and ${others} others`
  }
  return ''
}

export const getActivity = (notification) => {
  const {notification_type} = notification
  if ([1, 2, 3, 4, 11, 18, 12].includes(notification_type)) {
    // if (notification.metadata.vote === 1){
    return 'reacted to your'
    // }
  } else if ([5, 6, 7, 8, 10, 19].includes(notification_type)) {
    return 'commented on your'
  } else if ([14].includes(notification_type)) {
    return 'replied to your'
  } else if ([9].includes(notification_type)) {
    return 'signed your'
  }
  else if ([20].includes(notification_type)) {
    return 'approved your'
  }
}

// TODO names
export const getTarget = (notification) => {
  var startsWith = ''
  if ([1, 2, 5].includes(notification.notification_type)) {
    startsWith = 'poll'
  }
  // else if ([6, 9, 16].includes(notification.notification_type)){
  //     startsWith = 'petition';
  // }
  else if ([3, 7].includes(notification.notification_type)) {
    startsWith = 'article'
  } else if ([4, 8].includes(notification.notification_type)) {
    startsWith = 'discussion'
  } else if ([12, 13, 14].includes(notification.notification_type)) {
    startsWith = 'comment'
  }
  // else if ([10, 11].includes(notification.notification_type)){
  //     startsWith = 'platform';
  // }
  else if ([18, 19].includes(notification.notification_type)) {
    startsWith = 'post'
  }
  else if ([20].includes(notification.notification_type)) {
    startsWith = 'profile'
  }
  return ` ${startsWith}`
}

export const getText = (notification) => {
  const {notification_type, metadata} = notification
  if ([12, 13].includes(notification_type) && metadata.comment_text) {
    return metadata.comment_text
  } else if ([14].includes(notification_type) && metadata.comment_text) {
    return metadata.parent_comment_text
  }
  // else if (([15]).includes(notification_type)){
  //     return 'I signed up to volunteer!'
  // }
  else if (metadata && metadata.title) {
    return metadata.title
  }
}

// TODO improve
export const getLink = (notification) => {
  if (notification.content_model === 'post') {
    return `/posts/${notification.content_id}`
  } else if (notification.content_model === 'petition') {
    return `/petitions/${notification.content_id}`
  } else if (notification.content_model === 'comment') {
    if (notification.comment.post && notification.comment.post._id) {
      return `/posts/${notification.comment.post._id}`
    }
    return `/posts/${notification.comment.post}`
  } else if (notification.notification_type === 20){
    return `${ROUTE_PATHS.MY_PROFILE}`
  }
  return '#' // more todo
}
