/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useContext, useCallback, useRef} from 'react'
// import {IProfileDetails, profileDetailsInitValues as initialValues} from '../../../modules/profile/components/settings/SettingsModel'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useHistory} from 'react-router-dom'

import ReactPlayer from 'react-player'

import dateFormat, {masks} from 'dateformat'

import {Comments} from '../../../pages/Comments'

import '../../../pages/Feed.scss'

import POST_TYPE_COLORS from '../../../constants/PostTypeColors'

import {CopyToClipboard} from 'react-copy-to-clipboard'
import UserPictureItem from '../../reusable/UserPictureItem'
import {ProfileContext} from '../../../contexts/profilecontext'
import {GlobalPrivateModalContext} from '../../../contexts/GlobalModalContext'
import MODAL_NAMES from '../../../constants/ModalNames'
import FEED_SERVICE from '../../../services/feed/FeedService'
import NewsGuardLabel from '../../../pages/newsGuardLabel/NewsGuardLabel'
import ReadMoreText from '../../reusable/ReadMoreText'

import Linkify from 'linkify-react'

const linkify_options = {defaultProtocol: 'https', target: '_blank', rel: 'noopener noreferrer'}

type Props = {
  feed: any
  index?: number
  profilePage?: boolean
  isSinglePost: boolean
}

const PostItem = ({feed, index, profilePage = false, isSinglePost}: Props) => {
  const history = useHistory()
  const postOptionsRef = useRef(null)
  const [postOptionsDropdown, setPostOptionsDropdown] = useState(null)
  const [actionPopupContent, setActionPopupContent] = useState('Copied to clipboard')
  const [showElement, setShowElement] = React.useState(false)
  const [commentIndex, setCommentIndex] = useState(null)
  const [likeDisabled, setLikedDisabled] = useState<boolean>()
  const [liked, setLiked] = useState(feed.userPostVote ? true : false)
  const [numLikes, setNumLikes] = useState(feed.upvotes)

  const PROFILE_CONTEXT = useContext(ProfileContext)
  const userBasicProfileComplete = PROFILE_CONTEXT.profile?.basicProfileComplete
  const {setIsOpenGlobalModal} = useContext(GlobalPrivateModalContext)
  const setIsOpenCompleteProfile = (o) => setIsOpenGlobalModal(MODAL_NAMES.COMPLETE_PROFILE, o)

  const setIsOpenWrongCommunity = (o) => setIsOpenGlobalModal(MODAL_NAMES.WRONG_COMMUNITY, o)
  const userInCommunity = PROFILE_CONTEXT.profile?.communities?.includes(feed.community)

  const [newCommentTrigger, setNewCommentTrigger] = useState(false)

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        postOptionsDropdown &&
        postOptionsRef.current &&
        !postOptionsRef.current.contains(e.target)
      ) {
        setPostOptionsDropdown(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [postOptionsDropdown])

  useEffect(() => {
    setTimeout(function () {
      setShowElement(false)
    }, 5000)
  }, [showElement])

  const likePost = async (postId) => {
    setLikedDisabled(true)
    setLiked(true)
    setNumLikes(numLikes + 1)
    let response = await FEED_SERVICE.likePost(postId)
    if (response.code !== 200) {
      setLiked(false)
      setNumLikes(numLikes - 1)
    }
    setLikedDisabled(false)
  }

  const unlikePost = async (postId) => {
    setLikedDisabled(true)
    setLiked(false)
    setNumLikes(numLikes - 1)
    let response = await FEED_SERVICE.unlikePost(postId)
    if (response.code !== 200) {
      setLiked(true)
      setNumLikes(numLikes + 1)
    }
    setLikedDisabled(false)
  }

  const reportPost = async (postId) => {
    try {
      let response = await FEED_SERVICE.reportPost(postId)
      console.log('reported post response', response, postId)
    } catch (error) {
      console.log(error)
    }
  }

  const HASHTAG_FORMATTER = (string) => {
    return string
      .split(/(#[A-Za-z0-9-_]+)/gi)
      .filter(Boolean)
      .map((v, i) => {
        if (v.includes('#')) {
          return (
            <span key={i} className='civik-primary-blue'>
              {v}
            </span>
          )
        } else {
          return (
            <Linkify as='span' options={linkify_options}>
              {v}
            </Linkify>
          )
        }
      })
  }

  return (
    <div className={`feed__post ${profilePage ? 'MyPosts' : ''}`} key={feed._id}>
      {showElement && <div className='feed__postCopiedTooltip'>{actionPopupContent}</div>}

      <div className='feed__postHeader'>
        <div className='feed__postProfileOuterWrap'>
          <UserPictureItem
            item={feed}
            styleClasses={{
              anonPicStyle: 'feed__postProfileImg',
              profilePicStyle: 'feed__postProfileImg',
              letterPicStyle: ' navbar__barNameLetter',
              letterPicWrapperStyle: 'feed__postCommentBarLetterWrap feed__postHeaderLetterWrap',
            }}
          />
          <div className='feed__postProfileInnerWrap'>
            {feed.anonymous && (
              <span className='feed__postProfileInnerWrapName'>{feed.anon_username}</span>
            )}

            {feed.user && !feed.anonymous && (
              <span className='feed__postProfileInnerWrapName'>
                {feed.user.first_name + ' ' + feed.user.last_name}
              </span>
            )}

            <span className='feed__postProfileInnerWrapDate'>
              {feed.community ? feed.community + ', ' : ''}{' '}
              {dateFormat(feed.created_at, 'mmmm dS, h:MM TT')}
            </span>
          </div>
        </div>
        <div className='feed__postTypeOuterWrap '>
          {feed.topic && feed.topic !== 'Select a topic' && POST_TYPE_COLORS[feed.topic] && (
            <div
              className='feed__postTypeWrap mobile__hide'
              style={{borderColor: POST_TYPE_COLORS[feed.topic].borderColor}}
            >
              <span
                className='feed__postTypeTxt'
                style={{color: POST_TYPE_COLORS[feed.topic].color}}
              >
                {feed.topic}
              </span>
            </div>
          )}

          {feed.featured_post && (
            <div
              className='feed__postTypeWrap mobile-hide'
              style={{borderColor: POST_TYPE_COLORS.Featured.borderColor}}
            >
              <span
                className='feed__postTypeTxt'
                style={{color: POST_TYPE_COLORS.Featured.borderColor}}
              >
                Featured
              </span>
            </div>
          )}

          <div>
            <div
              className='feed__postElipsisContainer'
              onClick={() => {
                !userBasicProfileComplete
                  ? setIsOpenCompleteProfile(true)
                  : !userInCommunity
                  ? setIsOpenWrongCommunity(true)
                  : setPostOptionsDropdown(true)
              }}
            >
              <svg
                className='feed__postElipsis'
                width='18'
                height='5'
                viewBox='0 0 18 5'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <circle cx='15.8567' cy='2.14286' r='2.14286' fill='#6C7289' />
                <circle cx='9.00028' cy='2.14286' r='2.14286' fill='#6C7289' />
                <circle cx='2.14286' cy='2.14286' r='2.14286' fill='#6C7289' />
              </svg>
            </div>

            {postOptionsDropdown && (
              <div className='navbar__dropdown dropdown__postOptions' ref={postOptionsRef}>
                <div className='civik-flex-column dropdown__txtWrap'>
                  <div className='civik-flex-column '>
                    {feed.shareURL && (
                      <CopyToClipboard text={feed.shareURL}>
                        <div
                          className='dropdown__iconOptionWrap '
                          onClick={() => {
                            if (!userBasicProfileComplete) setIsOpenCompleteProfile(true)
                            else if (!userInCommunity) setIsOpenWrongCommunity(true)
                            else {
                              setShowElement(true)
                              setActionPopupContent('Copied to clipboard')
                              setPostOptionsDropdown(false)
                            }
                          }}
                        >
                          <span className='createPostModal_optionTxt'>Copy Post Link</span>
                        </div>
                      </CopyToClipboard>
                    )}

                    <div
                      className='dropdown__iconOptionWrap '
                      onClick={() => {
                        if (!userBasicProfileComplete) setIsOpenCompleteProfile(true)
                        else if (!userInCommunity) setIsOpenWrongCommunity(true)
                        else {
                          reportPost(feed._id)
                          setShowElement(true)
                          setActionPopupContent('Post Reported')
                          setPostOptionsDropdown(false)
                        }
                      }}
                    >
                      <span className='createPostModal_optionTxt'>Report Post</span>
                    </div>
                    {PROFILE_CONTEXT.profile?.admin && (
                      <div
                        className='dropdown__iconOptionWrap '
                        onClick={() => {
                          FEED_SERVICE.deletePost(feed._id)
                          setShowElement(true)
                          setActionPopupContent('Post Deleted, Refresh Feed')
                          setPostOptionsDropdown(false)
                        }}
                      >
                        <span className='createPostModal_optionTxt'>Delete Post</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className='feed__postBody'>
        <div className='feed__postTypeWrapMobile '>
          {feed.featured_post && (
            <div
              className='feed__postTypeWrap mobile-show'
              style={{borderColor: POST_TYPE_COLORS.Featured.borderColor}}
            >
              <span
                className='feed__postTypeTxt'
                style={{color: POST_TYPE_COLORS.Featured.borderColor}}
              >
                Featured
              </span>
            </div>
          )}

          {feed.topic && feed.topic !== 'Select a topic' && (
            <div
              className='feed__postTypeWrap mobile__show'
              style={{borderColor: POST_TYPE_COLORS[feed.topic].borderColor}}
            >
              <span
                className='feed__postTypeTxt'
                style={{color: POST_TYPE_COLORS[feed.topic].color}}
              >
                {feed.topic}
              </span>
            </div>
          )}
        </div>

        <h5 className='feed__postBodyTitle'>{feed.title}</h5>

        {feed.description ? (
          <ReadMoreText text={HASHTAG_FORMATTER(feed.description)} className='feed__postBodyTxt' />
        ) : (
          <div style={{marginBottom: 20}}></div>
        )}

        {/* if the post is a basic post */}
        {feed.post_type == 6 && feed.preview_image !== '' && (
          <div>
            {/* if a video was attached to this post */}
            {feed.media[0] && feed.media[0].display_type == 'video' && (
              <ReactPlayer
                url={feed.media[0].source_url}
                className='feed_postVideo'
                style={{
                  marginTop: feed.description ? 20 : 0,
                }}
              />
            )}
            {/*  if an image was attached to this post */}
            {feed.media[0] &&
              feed.media[0].display_type == 'image' &&
              feed.media[0].source_url !== '' && (
                <img
                  className='feed__postBodyPreviewImg'
                  style={{marginTop: feed.description ? 20 : 0}}
                  src={feed.preview_image}
                ></img>
              )}
          </div>
        )}

        {/* if the post is a news post */}
        {feed.post_type == 2 && feed.preview_image !== '' && (
          <div>
            <a href={feed.url} target='_blank' rel='noopener noreferrer'>
              <div className='feed__postBodyNewsImg'>
                <img
                  className='feed__postBodyPreviewImg'
                  style={{marginTop: feed.description ? 20 : 0}}
                  src={feed.preview_image}
                ></img>
              </div>
            </a>
            {feed.linkPreview && (
              <div className='feed__postBodyNewsOverlay'>
                <a href={feed.url} target='_blank' rel='noopener noreferrer'>
                  <span className='feed__postBodyNewsMetaTitle'>{feed.linkPreview.title}</span>
                </a>
                <br></br>
                <NewsGuardLabel post={feed} />
              </div>
            )}
          </div>
        )}
      </div>

      <div className='feed__postReactions'>
        <div className='feed__postReactionsStatsWrap'>
          <img
            className='feed__postReactionsLikeImg'
            src={toAbsoluteUrl('/media/design/like_icon_blue.svg')}
          />

          <span className='feed__postReactionsStatsTxt'>{numLikes}</span>
        </div>

        <div
          className='feed__postReactionsCommentWrap'
          onClick={() => history.push(`/posts/${feed._id}`)}
          style={{cursor: 'pointer'}}
        >
          <div className='civik-flex-row'>
            <span className='feed__postReactionsCommentStat'>{feed.no_of_comments}</span>
            <span className='feed__postReactionsCommentTxt'>Comments</span>
          </div>
        </div>
      </div>

      <div className='feed__postActionBar'>
        {liked ? (
          <div
            className='feed__postActionBarBtn '
            onClick={() => {
              !userBasicProfileComplete
                ? setIsOpenCompleteProfile(true)
                : !userInCommunity
                ? setIsOpenWrongCommunity(true)
                : likeDisabled
                ? console.log('like disabled')
                : unlikePost(feed._id)
            }}
          >
            <svg
              className={'feed__postActionBarBtnImg feed__postActionBarBtnLiked'}
              width='25'
              height='25'
              viewBox='0 0 25 25'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M18.5949 0H6.40514C2.86701 0 0 2.86701 0 6.40514V18.5949C0 22.133 2.86701 25 6.40514 25H18.5949C22.133 25 25 22.133 25 18.5949V6.40514C25 2.86701 22.133 0 18.5949 0ZM24.357 18.2804C24.357 21.636 21.636 24.357 18.2804 24.357H6.71965C3.36403 24.357 0.643042 21.636 0.643042 18.2804V6.71965C0.643042 3.36403 3.36403 0.643042 6.71965 0.643042H18.2804C21.636 0.643042 24.357 3.36403 24.357 6.71965V18.2804Z'
                fill='#6C7289'
              />
              <path
                d='M21.5267 10.8308C21.639 9.55592 20.4512 9.18245 19.9794 9.18245C18.1514 9.18245 16.3234 9.18245 14.4953 9.18245C14.7593 8.60961 15.0794 7.09046 14.675 4.99847C14.515 4.1701 14.0264 3.62253 13.4058 3.63096C12.906 3.63938 12.4258 4.02689 12.3753 4.50706C12.3191 5.03778 12.1899 5.79034 12.0664 6.30702C11.6732 7.95534 9.56721 10.1316 8.2362 10.8926V10.915L8.21093 10.9038V10.1793C8.21093 9.86481 7.9582 9.61208 7.6437 9.61208H3.99887C3.68436 9.61208 3.43164 9.86481 3.43164 10.1793V19.2886C3.43164 19.6031 3.68717 19.8558 3.99887 19.8558H7.6437C7.9582 19.8558 8.21093 19.6003 8.21093 19.2886V18.946H8.23901C9.14039 19.4543 10.1204 19.634 11.165 19.6143C12.8189 19.5834 17.5392 19.6115 18.3957 19.6059C19.0022 19.6031 19.606 19.3139 19.8952 18.581C20.2911 17.5841 19.269 17.0843 19.269 17.0843C19.269 17.0843 20.3894 17.1404 20.6449 16.1352C20.9061 15.1046 19.8783 14.6974 19.8783 14.6974C20.5747 14.6216 21.0212 14.4391 21.1897 13.8129C21.3891 13.0744 20.9117 12.5184 20.1591 12.1955C20.2743 12.1843 21.4228 11.9989 21.5267 10.8308Z'
                fill='#6C7289'
              />
            </svg>

            <span className={'feed__postActionBarBtnTxt feed__postActionBarBtnTxtLiked'}>Like</span>
          </div>
        ) : (
          <div
            className='feed__postActionBarBtn '
            onClick={() => {
              !userBasicProfileComplete
                ? setIsOpenCompleteProfile(true)
                : !userInCommunity
                ? setIsOpenWrongCommunity(true)
                : likeDisabled
                ? console.log('like disabled')
                : likePost(feed._id)
            }}
          >
            <svg
              className={
                liked
                  ? 'feed__postActionBarBtnImg feed__postActionBarBtnLiked'
                  : 'feed__postActionBarBtnImg'
              }
              width='25'
              height='25'
              viewBox='0 0 25 25'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M18.5949 0H6.40514C2.86701 0 0 2.86701 0 6.40514V18.5949C0 22.133 2.86701 25 6.40514 25H18.5949C22.133 25 25 22.133 25 18.5949V6.40514C25 2.86701 22.133 0 18.5949 0ZM24.357 18.2804C24.357 21.636 21.636 24.357 18.2804 24.357H6.71965C3.36403 24.357 0.643042 21.636 0.643042 18.2804V6.71965C0.643042 3.36403 3.36403 0.643042 6.71965 0.643042H18.2804C21.636 0.643042 24.357 3.36403 24.357 6.71965V18.2804Z'
                fill='#6C7289'
              />
              <path
                d='M21.5267 10.8308C21.639 9.55592 20.4512 9.18245 19.9794 9.18245C18.1514 9.18245 16.3234 9.18245 14.4953 9.18245C14.7593 8.60961 15.0794 7.09046 14.675 4.99847C14.515 4.1701 14.0264 3.62253 13.4058 3.63096C12.906 3.63938 12.4258 4.02689 12.3753 4.50706C12.3191 5.03778 12.1899 5.79034 12.0664 6.30702C11.6732 7.95534 9.56721 10.1316 8.2362 10.8926V10.915L8.21093 10.9038V10.1793C8.21093 9.86481 7.9582 9.61208 7.6437 9.61208H3.99887C3.68436 9.61208 3.43164 9.86481 3.43164 10.1793V19.2886C3.43164 19.6031 3.68717 19.8558 3.99887 19.8558H7.6437C7.9582 19.8558 8.21093 19.6003 8.21093 19.2886V18.946H8.23901C9.14039 19.4543 10.1204 19.634 11.165 19.6143C12.8189 19.5834 17.5392 19.6115 18.3957 19.6059C19.0022 19.6031 19.606 19.3139 19.8952 18.581C20.2911 17.5841 19.269 17.0843 19.269 17.0843C19.269 17.0843 20.3894 17.1404 20.6449 16.1352C20.9061 15.1046 19.8783 14.6974 19.8783 14.6974C20.5747 14.6216 21.0212 14.4391 21.1897 13.8129C21.3891 13.0744 20.9117 12.5184 20.1591 12.1955C20.2743 12.1843 21.4228 11.9989 21.5267 10.8308Z'
                fill='#6C7289'
              />
            </svg>

            <span
              className={
                liked
                  ? 'feed__postActionBarBtnTxt feed__postActionBarBtnTxtLiked'
                  : 'feed__postActionBarBtnTxt'
              }
            >
              Like
            </span>
          </div>
        )}

        <div
          className='feed__postActionBarBtn mobile-hide'
          onClick={() => {
            if (!userBasicProfileComplete) {
              setIsOpenCompleteProfile(true)
            } else {
              if (!userInCommunity) {
                setIsOpenWrongCommunity(true)
              } else {
                setCommentIndex(index)
                setNewCommentTrigger(true)
              }
            }

            // !userBasicProfileComplete
            //   ? setIsOpenCompleteProfile(true)
            //   : !userInCommunity
            //   ? setIsOpenWrongCommunity(true)
            //   : setCommentIndex(index)
          }}
        >
          <img
            className='feed__postActionBarBtnImg'
            src={toAbsoluteUrl('/media/design/comment_default_icon.svg')}
          />
          <span className='feed__postActionBarBtnTxt'>Comment</span>
        </div>

        <div
          className='feed__postActionBarBtn mobile-show'
          onClick={() => {
            if (!userBasicProfileComplete) {
              setIsOpenCompleteProfile(true)
            } else {
              if (!userInCommunity) {
                setIsOpenWrongCommunity(true)
              } else {
                setCommentIndex(index)
                setNewCommentTrigger(true)
                history.push(`/posts/${feed._id}`)
              }
            }

            // !userBasicProfileComplete
            //   ? setIsOpenCompleteProfile(true)
            //   : !userInCommunity
            //   ? setIsOpenWrongCommunity(true)
            //   : setCommentIndex(index)
          }}
        >
          <img
            className='feed__postActionBarBtnImg'
            src={toAbsoluteUrl('/media/design/comment_default_icon.svg')}
          />
          <span className='feed__postActionBarBtnTxt'>Comment</span>
        </div>

        {feed.shareURL && (
          <CopyToClipboard text={feed.shareURL}>
            <div
              className='feed__postActionBarBtn'
              onClick={() => {
                if (!userBasicProfileComplete) setIsOpenCompleteProfile(true)
                else if (!userInCommunity) setIsOpenWrongCommunity(true)
                else {
                  setActionPopupContent('Copied to clipboard')
                  setShowElement(true)
                }
              }}
            >
              <img
                className='feed__postActionBarBtnImg'
                src={toAbsoluteUrl('/media/design/share_default_icon.svg')}
              />
              <span className='feed__postActionBarBtnTxt'>Share</span>
            </div>
          </CopyToClipboard>
        )}
      </div>

      {isSinglePost ? (
        <Comments
          numOfComments={feed.no_of_comments}
          postId={feed._id}
          sortBy={'recent'}
          community={feed.community}
          triggerComment={newCommentTrigger}
          isSinglePost={true}
        />
      ) : (
        <div className='mobile-hide'>
          <Comments
            numOfComments={feed.no_of_comments}
            postId={feed._id}
            sortBy={'recent'}
            community={feed.community}
            triggerComment={newCommentTrigger}
            comments={feed.comments}
          />
        </div>
      )}
    </div>
  )
}
export default PostItem
