import React, {Suspense, lazy, useContext} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {Profile} from '../pages/profile/Profile'
import PetitionPDF from '../pages/petitions/PetitionPDF'
import {PetitionDetails} from '../pages/petitions/PetitionDetails'
import {PetitionIframe} from '../pages/petitions/PetitionIframe'
import {Home} from '../pages/Home'
import {MenuTestPage} from '../pages/MenuTestPage'
import {PublicProfile} from '../pages/profile/PublicProfile'
import {Navbar} from '../resuable/navbar/Navbar'
import {Error404} from '../modules/errors/components/Error404'
import ROUTE_PATHS from './RoutePaths'
import PrivateRoutesModals from './PrivateRoutesModals'
import Post from '../pages/Post'
import {AuthPage} from '../modules/auth'
import Terms from '../pages/Terms'
import PrivacyPolicy from '../pages/PrivacyPolicy'
import {MobileNavbar} from '../resuable/navbar/MobileNavbar'
import './PrivateRoutes.scss'

export function PrivateRoutes() {
  // const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  // const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  // const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  // const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  // const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  // const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))

  return (
    // <Suspense fallback={<FallbackView />}>
    <PrivateRoutesModals>
      <Switch>
        <Route path='/auth/' render={(props) => <AuthPage {...props} verifyOnly={true} />} />

        <div className='privateRoutes__container'>
          <Navbar />
          <Switch>
            {/* <Route path='/dashboard' component={DashboardWrapper} />
        <Route path='/builder' component={BuilderPageWrapper} />
        <Route path='/crafted/pages/profile' component={ProfilePage} />
        <Route path='/crafted/pages/wizards' component={WizardsPage} />
        <Route path='/crafted/widgets' component={WidgetsPage} />
        <Route path='/crafted/account' component={AccountPage} />
        <Route path='/apps/chat' component={ChatPage} />
        <Route path='/menu-test' component={MenuTestPage} /> */}
            {/* TODO: check this still works with latest styles*/}
            <Route exact path='/petitions/pdf/:petition_id' component={PetitionPDF} />
            {/* <Route exact path='/petitions/:petition_id' component={PetitionDetails} /> */}
            {/* <Route exact path='/petitions/:petition_id/sign' component={PetitionIframe} /> */}
            <Route exact path={'/posts/:postId'} component={Post} />

            <Route exact path={ROUTE_PATHS.HOME} component={Home} />
            <Route exact path={ROUTE_PATHS.FEED} component={Home} />

            <div className='profile__outerWrap'>
              <Route exact path={ROUTE_PATHS.MY_PROFILE} component={Profile} />
              <Route exact path='/u/:handle' component={PublicProfile} />
              <Route exact path='/p/:handle' component={PublicProfile} />
              <Route exact path='/pending/p/:handle' component={PublicProfile} />
              <Route path='/terms' component={Terms} />
              <Route path='/privacypolicy' component={PrivacyPolicy} />
            </div>
            {/* if user is logged in and clicks verify email link for other email */}

            {/* <Route exact path='*' component={Error404} /> */}

            <Redirect to={ROUTE_PATHS.HOME} />

            {/* <PrivateRoute exact path="*" component={NotFound} /> */}
          </Switch>
          <MobileNavbar />
        </div>
      </Switch>
    </PrivateRoutesModals>
  )
}
