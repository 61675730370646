/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

// import React, {FC} from 'react'
import {useState, useEffect, useContext} from 'react'
import {Switch, Route, useHistory} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {PrivateRoutes} from './PrivateRoutes'
import {Logout, AuthPage, AuthCouncilorPages} from '../modules/auth'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {MasterInit} from '../../_metronic/layout/MasterInit'
import {UserContext} from '../contexts/usercontext'
// import AUTH_SERVICE from '../services/auth/AuthService'

import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import AuthService from '../services/auth/AuthService'
import {ProfileContext} from '../contexts/profilecontext'
import {AccountStepContext} from '../contexts/accountStepContext'
import ROUTE_PATHS from './RoutePaths'
import {PublicRoutes} from './PublicRoutes'
import {CreatePostContext} from '../contexts/createPostContext'

const Routes = () => {
  const history = useHistory()

  const [initializing, setInitializing] = useState(true)

  const [loading, setLoading] = useState(true)

  const [firebaseUser, setFirebaseUser] = useState()

  const USER_CONTEXT = useContext(UserContext)
  const PROFILE_CONTEXT = useContext(ProfileContext)

  const [showModal, setShowModal] = useState({visible: false, postType: 'Basic Post'})

  function isSocialAccount(provider) {
    try {
      console.log('provider', provider)
      const socials = [
        'twitter.com',
        'facebook.com',
        'apple.com',
        'google.com',
        // 'linkedin.com': true,
      ]

      return socials.indexOf(provider) !== -1 ? true : false
    } catch (error) {
      console.log()
    }
  }

  async function getProfile() {
    // fetch civik user (the active firebase user who just logged in)
    // NB: the asynchronous nature of this function means it will cause delayed redirects if placed before history.push verify email
    // NOTE: may need to handle userProfile not being returned
    try {
      let userProfile = await AuthService.getCurrentUser()
      PROFILE_CONTEXT.setProfile(userProfile)
      if (userProfile.__t == 'pendingPolitician' && !userProfile.onboardingComplete) {
        console.log('onboarding in progress')
        USER_CONTEXT.setUser({loggedIn: true, verified: true, secondarySignup: true})
      } else {
        console.log('complete onboarding')
        //set user context logged in
        USER_CONTEXT.setUser({loggedIn: true, verified: true, secondarySignup: false})
      }

      setLoading(false)
    } catch {
      console.log('error fetching profile')
    }
  }
  // navigate to approriate screen on app launch
  async function onAuthStateChanged(firebaseUser) {
    setFirebaseUser(firebaseUser)

    if (initializing) setInitializing(false)

    console.log('firebaseUser', firebaseUser)
    // console.log('USER_CONTEXT.user', USER_CONTEXT.user)

    // if the the user has a firebase account (logged in)
    if (firebaseUser) {
      // if their account is a socialAccount (they already are email verified)
      // politicians must signup through email for now
      if (isSocialAccount(firebaseUser.providerData[0].providerId)) {
        console.log('USER IS VERIFIED -> REDIRECT TO MAIN SCREENS')
        //login user
        // NOTE: on the first sign up with social this will sometimes run bonefore the account is created on civik
        // but we need a call to fetch the profile here because it needs to be fetched every session
        // so this handled by additionally setting the profile in AuthService.loginWithSocial
        // and only setting the profile in getProfile if it exists
        if (PROFILE_CONTEXT.profile?._id) {
          await getProfile()
        } else {
          setTimeout(() => getProfile(), 500)
        }
      } else {
        // else, their account is a email & password type
        // if their email is verified
        if (firebaseUser.emailVerified) {
          await getProfile()

          console.log('EMAIL PASSWORD USER - USER IS VERIFIED -> REDIRECT TO MAIN SCREENS')
        } else {
          // else, redirect them to the email verify screen
          console.log('USER IS NOT VERIFIED -> REDIRECT TO VERIFY SCREEN')
          USER_CONTEXT.setUser({loggedIn: true, verified: false})
          setLoading(false)
          history.push(ROUTE_PATHS.VERIFY)
        }
      }
    } else {
      console.log('USER DOES NOT EXIST -> REDIRECT TO AUTH SCREEN')
      USER_CONTEXT.setUser({loggedIn: false, verified: false, secondarySignup: false})
      PROFILE_CONTEXT.setProfile({})
      setLoading(false)
      if (
        !window.location.pathname?.includes('auth') &&
        !window.location.pathname?.includes('terms') &&
        !window.location.pathname?.includes('privacy') &&
        !window.location.pathname?.includes('pdf')
      ) {
        history.replace(ROUTE_PATHS.LOGIN)
      }
    }
  }

  const [step, setStep] = useState(1)

  useEffect(() => {
    const subscriber = firebase.auth().onAuthStateChanged(onAuthStateChanged)
    return subscriber
  }, [USER_CONTEXT.setUser])

  if (initializing) return <div />
  else if (loading) return <div />

  // private theme when user in anonymous mode
  return (
    <div style={{maxWidth: '100%'}}>
      <Switch>
        {USER_CONTEXT.user &&
        USER_CONTEXT.user.loggedIn &&
        USER_CONTEXT.user.verified &&
        USER_CONTEXT.user.secondarySignup ? (
          <AccountStepContext.Provider value={{step, setStep}}>
            <AuthCouncilorPages />
          </AccountStepContext.Provider>
        ) : USER_CONTEXT.user && // CASE 1 - USER IS LOGGED IN AND VERIFIED
          USER_CONTEXT.user.loggedIn &&
          USER_CONTEXT.user.verified &&
          !USER_CONTEXT.user.secondarySignup ? (
          // Then, show the private screens
          <CreatePostContext.Provider value={{showModal, setShowModal}}>
            <PrivateRoutes />
          </CreatePostContext.Provider>
        ) : (
          // Else, show the public screens

          // <Route>
          <PublicRoutes />
          // </Route>
        )}
      </Switch>

      <MasterInit />
    </div>
  )
}

export {Routes}
