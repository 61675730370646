import React, {Component} from 'react'
import clsx from 'clsx'

import './SelectField.scss'

class TextField extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const {formik, type, field, placeholder, label, isRequired, noLabel, customOnChange} =
      this.props

    const selectOptions = this.props.options.map((option) => (
      <option key={option.label} value={option.value}>
        {option.label}
      </option>
    ))

    return (
      <div>
        <label
          className={
            isRequired && !noLabel
              ? 'form-label fs-6 fw-bold text-dark required'
              : 'form-label fs-6 fw-bold text-dark'
          }
        >
          {label}
        </label>
        {customOnChange ? (
          <select
            className='SelectField '
            {...formik.getFieldProps(field)}
            name={field}
            onChange={customOnChange}
          >
            {selectOptions}
          </select>
        ) : (
          <select className='SelectField ' {...formik.getFieldProps(field)} name={field}>
            {selectOptions}
          </select>
        )}

        {formik.touched[field] && formik.errors[field] && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>{formik.errors[field]}</div>
          </div>
        )}
      </div>
    )
  }
}

export default TextField
