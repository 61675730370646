/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useContext, useCallback} from 'react'
import dateFormat, {masks} from 'dateformat'
// import {IProfileDetails, profileDetailsInitValues as initialValues} from '../../../modules/profile/components/settings/SettingsModel'
import {useHistory} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'

import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PasswordMeterComponent} from '../../../_metronic/assets/ts/components'

import Modal from 'react-modal'

import {UserContext} from '../../contexts/usercontext'

import FeatherIcon from 'feather-icons-react'
import TextField from '../../modules/auth/reusable/TextField'

import POLITICIAN_SERIVCE from '../../services/politician/PoliticianService'
import {ProfileContext} from '../../contexts/profilecontext'
import AUTH_SERVICE from '../../services/auth/AuthService'

import {CreatePostContext} from '../../contexts/createPostContext'

import {ProfilePageContext} from '../../contexts/profilePageContext'

import {FeedLocationContext} from '../../contexts/feedLocationContext'

import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'

import NotificationModal from '../../pages/modals/NotificationModal'

import ROUTE_PATHS from '../../routing/RoutePaths'

import WeatherIcon from 'react-open-weather-icons'

import './MobileNavbar.scss'
import UserPicture from '../../modules/reusable/UserPicture'

import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
  getCity,
} from 'react-places-autocomplete'

import OPEN_WEATHER_CONFIG from '../../openWeatherConfig.json'
import {PrivacyPolicyLink, TermsLink} from '../../constants/PrivacyTermsLinks'
import MODAL_NAMES from '../../constants/ModalNames'
import {GlobalPrivateModalContext} from '../../contexts/GlobalModalContext'

const customStyles = {
  content: {
    borderRadius: '4px',
    border: 'none',
    width: '80%',
    height: '100%',
    top: 0,
    left: 0,
    padding: 'none',
    maxWidth: 400,
  },

  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
    zIndex: 99999999,
  },
}

const now = new Date()

export function MobileNavbar(props) {
  const history = useHistory()

  const CREATE_POST_CONTEXT = useContext(CreatePostContext)
  const {currentTab, setCurrentTab} = useContext(ProfilePageContext)
  const {feedLocation, setFeedLocation} = useContext(FeedLocationContext)

  const [notificationBadgeNum, setNotificationBadgeNum] = useState(0)

  const [isActivePg, setIsActivePg] = useState('Feed')

  const [sideNavbarOpen, setSideNavbarOpen] = useState(false)

  const PROFILE_CONTEXT = useContext(ProfileContext)

  const userBasicProfileComplete = PROFILE_CONTEXT.profile?.basicProfileComplete
  const {setIsOpenGlobalModal} = useContext(GlobalPrivateModalContext)
  const setIsOpenCompleteProfile = (o) => setIsOpenGlobalModal(MODAL_NAMES.COMPLETE_PROFILE, o)

  const [weatherInfo, setWeatherInfo] = useState({
    main: {temp: 0, feels_like: 0},
    weather: [{icon: ''}],
  })

  const getCurrentWeather = async (coordinates) => {
    try {
      let response = await fetch(
        `https://api.openweathermap.org/data/2.5/weather?lat=${coordinates.lat}&lon=${coordinates.lng}&appid=${OPEN_WEATHER_CONFIG.key}&units=metric`
      )

      let result = await response.json()
      setWeatherInfo(result)
      console.log(result)
    } catch (error) {
      console.log(error)
    }
  }

  const getCoordinatesProfile = async () => {
    try {
      let address =
        PROFILE_CONTEXT.profile.location_city +
        ', ' +
        PROFILE_CONTEXT.profile.location_region +
        ', ' +
        PROFILE_CONTEXT.profile.postal_code

      const results = await geocodeByAddress(address)
      const latLng = await getLatLng(results[0])

      getCurrentWeather(latLng)

      console.log(latLng)
    } catch (error) {
      console.log(error)
    }
  }
  const logout = () => {
    PROFILE_CONTEXT.setProfile({})
    firebase
      .auth()
      .signOut()
      .then(() => console.log('User signed out!'))
    setFeedLocation({country: 'Canada'})
    history.push(ROUTE_PATHS.LOGIN)
  }

  useEffect(() => {
    if (PROFILE_CONTEXT.profile.coords) {
      getCurrentWeather(PROFILE_CONTEXT.profile.coords)
    } else if (PROFILE_CONTEXT.profile.location_city && PROFILE_CONTEXT.profile.location_region) {
      getCoordinatesProfile()
    }
  }, [PROFILE_CONTEXT.profile.location_city])

  return (
    <div>
      <div className='mobile__topNavbar'>
        <img
          onClick={() => {
            setIsActivePg('Feed')
            history.push(ROUTE_PATHS.HOME)
          }}
          alt='civik blue logo'
          src={toAbsoluteUrl('/media/design/logo-civik-blue-horizontal.svg')}
          className=' mobile__topNavbar__logo'
        />

        {PROFILE_CONTEXT.profile?.first_name ? (
          <div
            className='navbar__elipsisContainer'
            onClick={() => {
              setSideNavbarOpen(true)
            }}
          >
            <span className='navbar__elipsisGreetingTxt' style={{marginRight: 15, fontSize: 14}}>
              Hi, {PROFILE_CONTEXT.profile.first_name}
            </span>
            <UserPicture
              isReply={false}
              profile={PROFILE_CONTEXT.profile}
              styleClasses={{
                profilePic: 'navbar__elipsisProfileImg',
                letterWrap: 'navbar__elipsisNameLetterWrap',
                nameLetter: ' navbar__elipsisNameLetter',
              }}
            />
          </div>
        ) : (
          <div
            className='navbar__elipsisContainer'
            onClick={() => {
              setSideNavbarOpen(true)
            }}
            style={{cursor: 'pointer'}}
          >
            <img src={toAbsoluteUrl('/media/design/elipsis_icon.svg')} style={{width: 20}} />
          </div>
        )}
      </div>

      {/* SIDE NAVBAR  START --------------------------------------- */}

      <Modal
        ariaHideApp={false}
        isOpen={sideNavbarOpen}
        onRequestClose={() => {
          setSideNavbarOpen(false)
        }}
        style={customStyles}
        contentLabel='Example Modal'
      >
        <div className='mobile__sideNavbar'>
          <div className='mobile__sideNavbarProfileWrap'>
            <div className='civik-flex-row'>
              <UserPicture
                isReply={false}
                profile={PROFILE_CONTEXT.profile}
                styleClasses={{
                  profilePic: 'navbar__elipsisProfileImg',
                  letterWrap: 'navbar__elipsisNameLetterWrap',
                  nameLetter: ' navbar__elipsisNameLetter',
                }}
              ></UserPicture>

              <div className='mobile__sideNavbarProfileTxtWrap'>
                {PROFILE_CONTEXT.profile?.full_name && (
                  <span className='mobile__sideNavbarName' style={{marginLeft: 15, fontSize: 14}}>
                    {PROFILE_CONTEXT.profile.full_name}
                  </span>
                )}

                <a
                  className='mobile__sideNavbarProfileLink'
                  onClick={() => {
                    setSideNavbarOpen(false)
                    history.push(ROUTE_PATHS.MY_PROFILE)
                  }}
                >
                  View Profile
                </a>
              </div>
            </div>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/design/modal-x-btn.svg')}
              className='civik-modal-x-btn mobile__sideNavbarClose'
              onClick={() => {
                setSideNavbarOpen(false)
              }}
            />
          </div>
          <hr className='mobile__sideNavbarSeparator'></hr>

          <div className='mobile__sideNavbarLinks'>
            <span
              onClick={() => {
                setSideNavbarOpen(false)
                setCurrentTab('Settings')
                history.push(ROUTE_PATHS.MY_PROFILE)
              }}
            >
              Account Settings
            </span>

            <span
              onClick={() => {
                setSideNavbarOpen(false)
                setCurrentTab('My Posts')
                history.push(ROUTE_PATHS.MY_PROFILE)
              }}
            >
              My Posts
            </span>
            <span
              onClick={() => {
                setSideNavbarOpen(false);
                 // @ts-ignore: Unreachable code error    
                window.openSupportWidget();
              }}
            >
              Help & Support
            </span>
            <span>
              <a href={PrivacyPolicyLink} target='_blank' rel='noopener noreferrer'>
                <span>Privacy Policy</span>
              </a>
            </span>
            <span>
              <a
                href={TermsLink}
                target='_blank'
                rel='noopener noreferrer'
                className='   fw-bolder fs-6'
              >
                <span>Terms & Conditions</span>
              </a>
            </span>
            {/* <span>Notifications</span> */}
          </div>
          <div>
            {PROFILE_CONTEXT.profile.location_city && (
              <div className='rightSideBar__weather mobile__sideNavbarWeather'>
                <div className=' '>
                  <h3 className='rightSideBar__weatherLocation'>
                    {PROFILE_CONTEXT.profile.location_city}
                  </h3>
                  <small className='rightSideBar__weatherDate'>
                    {dateFormat(now, 'dddd, mmmm dS, yyyy')}
                  </small>
                </div>

                <div className='civik-flex-column'>
                  <hr></hr>
                  <span className='rightSideBar__weatherLabel'>TEMPERATURE</span>
                </div>

                <div className='rightSideBar__weatherInfoContainer civik-flex-row'>
                  <div className='civik-flex-column'>
                    <h1 className='rightSideBar__weatherTemperature'>
                      {Math.trunc(weatherInfo.main.temp)}
                    </h1>
                    <small className='rightSideBar__weatherFeelsLike'>
                      Feels like: {Math.ceil(weatherInfo.main.feels_like)}
                    </small>
                  </div>

                  <WeatherIcon
                    name={weatherInfo.weather[0].icon}
                    className='rightSideBar__weatherIcon'
                  />
                </div>
              </div>
            )}

            <div className='leftSideBar__onlineVotingBox mobile__sideNavbarVoting'>
              <p>Find your electoral district, look at the map, profile, etc.</p>
              <button>Online Voting</button>
            </div>

            <hr className='mobile__sideNavbarSeparator'></hr>

            <button className='mobile__sideNavbarLogout' onClick={logout}>
              <span>Logout</span>
            </button>
          </div>
        </div>
      </Modal>

      {/* SIDE NAVBAR  END --------------------------------------- */}

      <div className='mobile__bottomNavbar'>
        <div className='mobile__bottomNavbarIcons'>
          {/* LEFT SIDE ICONS */}

          <div className='mobile__bottomNavbarIcons__leftSide'>
            {isActivePg == 'Feed' ? (
              <div
                className='mobile__bottomNavbar__feedBtn mobile__bottomNavbarIcon__feedBtn'
                onClick={() => {
                  history.push(ROUTE_PATHS.HOME)
                }}
              >
                <img
                  className='mobile__bottomNavbarIcon__feed'
                  src={toAbsoluteUrl('/media/design/nav_house_icon_active.svg')}
                />
                <span className='mobile__bottomNavbar__activeBtnTxt '>Feed</span>
              </div>
            ) : (
              <div
                className='mobile__bottomNavbar__feedBtn mobile__bottomNavbarIcon__feedBtn'
                onClick={() => {
                  setIsActivePg('Feed')
                  history.push(ROUTE_PATHS.HOME)
                }}
              >
                <img
                  className='mobile__bottomNavbarIcon__feed'
                  src={toAbsoluteUrl('/media/design/nav_house_icon.svg')}
                />
                <span className='mobile__bottomNavbar__defaultBtnTxt '>Feed</span>
              </div>
            )}
            {/* {isActivePg == 'My Posts' ? (
              <div
                className='mobile__bottomNavbar__feedBtn'
                onClick={() => {
                  history.push(ROUTE_PATHS.MY_PROFILE)
                }}
              >
                <img
                  className='mobile__bottomNavbarIcon__myPosts'
                  src={toAbsoluteUrl('/media/design/nav_posts_icon_active.svg')}
                />
                <span className='mobile__bottomNavbar__activeBtnTxt '>My Posts</span>
              </div>
            ) : (
              <div
                className='mobile__bottomNavbar__feedBtn'
                onClick={() => {
                  setCurrentTab('My Posts')
                  setIsActivePg('My Posts')
                  history.push(ROUTE_PATHS.MY_PROFILE)
                }}
              >
                <img
                  className='mobile__bottomNavbarIcon__myPosts'
                  src={toAbsoluteUrl('/media/design/nav_my_posts_icon.svg')}
                />
                <span className='mobile__bottomNavbar__defaultBtnTxt '>My Posts</span>
              </div>
            )}{' '} */}
          </div>

          {/* PLUS ICON (CENTER) */}
          <div
            className='mobile__bottomNavbar__feedBtn '
            onClick={() => {
              if (!userBasicProfileComplete) setIsOpenCompleteProfile(true)
              else {
                setIsActivePg('Feed')
                history.push(ROUTE_PATHS.HOME)
                CREATE_POST_CONTEXT.setShowModal({visible: true, postType: 'Basic Post'})
              }
            }}
          >
            <img
              className='mobile__bottomNavbarIcon__plusIcon '
              src={toAbsoluteUrl('/media/design/add_plus_btn.svg')}
            />
          </div>

          {/* RIGHT SIDE ICONS */}

          <div className='mobile__bottomNavbarIcons__rightSide'>
            {/* {isActivePg == 'Petitions' ? (
            <div
              className='mobile__bottomNavbar__feedBtn mobile__bottomNavbarIcon__petitionBtn'
              onClick={() => {}}
            >
              <img
                className='mobile__bottomNavbarIcon__petitions'
                src={toAbsoluteUrl('/media/design/nav_petitions_icon_active.svg')}
              />
              <span className='mobile__bottomNavbar__activeBtnTxt '>Petitions</span>
            </div>
          ) : (
            <div
              className='mobile__bottomNavbar__feedBtn mobile__bottomNavbarIcon__petitionBtn'
              onClick={() => {
                setIsActivePg('Petitions')
              }}
            >
              <img
                className='mobile__bottomNavbarIcon__petitions'
                src={toAbsoluteUrl('/media/design/nav_petitions_icon.svg')}
              />
              <span className='mobile__bottomNavbar__defaultBtnTxt '>Petitions</span>
            </div>
          )} */}

            <div
              className='mobile__bottomNavbar__feedBtn mobile__bottomNavbarIcon__notifBtn'
              onClick={() => {
                // history.push(ROUTE_PATHS.HOME)
              }}
            >
              <div
                data-kt-menu-trigger='click'
                data-kt-menu-attach='parent'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='bottom'
                className='d-flex align-items-center'
              >
                <img
                  className='mobile__bottomNavbarIcon__notif'
                  src={toAbsoluteUrl('/media/design/nav_notifs_icon.svg')}
                />
                {notificationBadgeNum && notificationBadgeNum > 0 ? (
                  <div className='notification-badge'>{notificationBadgeNum}</div>
                ) : null}
              </div>
              <span className='mobile__bottomNavbar__defaultBtnTxt '>Notifications</span>
              <NotificationModal setBadgeNum={setNotificationBadgeNum} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
