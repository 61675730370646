import {useState, useEffect} from 'react'
import {ProfileHeader} from '../../modules/profile/ProfileHeader'
import VoterListAssignPins from '../../modules/profile/VoterListAssignPins'
import VoterListEmailPins from '../../modules/profile/VoterListEmailPins'
import AdminService from '../../services/admin/AdminService'

const SubTab = ({tabName, isActive, onClick}) => (
  <button
    onClick={onClick}
    className={isActive ? `civik-white-btn` : `profile__subtitlebtn`}
    style={{marginLeft: 20}}
  >
    {tabName}
  </button>
)

const VoterListTab = ({currentTab, setCurrentTab, profile}) => {
  const subtabs = ['Assign Voter Pins', 'Email Voter Pins']

  const [currentSubTab, setCurrentSubTab] = useState(subtabs[0])
  const [uploadHistory, setUploadHistory] = useState(null)

  const getVoterPinFileUploadHistory = async () => {
    let result = await AdminService.getVoterPinFileUploadHistory()
    if (result.success) {
      // @ts-ignore
      setUploadHistory(result.data)
    }
  }

  useEffect(() => {
    getVoterPinFileUploadHistory()
  }, [])

  return (
    <div className='Profile form w-100 fv-plugins-bootstrap5 fv-plugins-framework'>
      <ProfileHeader currentTab={currentTab} setCurrentTab={setCurrentTab} profile={profile} />

      <div className='profile__subtitleBar'>
        {subtabs.map((subtab) => (
          <SubTab
            tabName={subtab}
            isActive={currentSubTab == subtab}
            onClick={() => setCurrentSubTab(subtab)}
          />
        ))}
      </div>
      {currentSubTab === subtabs[0] && (
        <div className='profile__contentOuterWrap w-100'>
          <div className='civik-flex-column w-100'>
            <VoterListAssignPins
              uploadHistory={uploadHistory?.voterPinFileHistory?.reverse()}
              getUploadHistory={getVoterPinFileUploadHistory}
            />
          </div>
        </div>
      )}
      {currentSubTab === subtabs[1] && (
        <div className='profile__contentOuterWrap w-100'>
          <div className='civik-flex-column w-100'>
            <VoterListEmailPins
              uploadHistory={uploadHistory?.voterPinEmailFileHistory?.reverse()}
              getUploadHistory={getVoterPinFileUploadHistory}
            />
          </div>
        </div>
      )}
    </div>
  )
}
export default VoterListTab
