
export const parseFeedFilters = (feedFilter, feedLocation) => {
    let userInput = {
        world: '',
        country: '',
        region: '',
        city: '',
      }
      switch (feedFilter.communityLevel) {
        case 'All':
          userInput = {
            world: 'World',
            country: feedLocation.country,
            region: feedLocation.region,
            city: feedLocation.city,
          }
          break
        case 'International':
          userInput = {
            ...userInput,
            world: feedFilter.value,
          }
          break
        case 'Federal':
          userInput = {
            ...userInput,
            country: feedFilter.value,
          }
          break
        case 'Provincial':
          userInput = {
            ...userInput,
            region: feedFilter.value,
          }
          break
        case 'Municipal':
          userInput = {
            ...userInput,
            city: feedFilter.value,
          }
          break
        default:
          userInput = {
            world: 'World',
            country: feedLocation.country,
            region: feedLocation.region,
            city: feedLocation.city,
          }
          break
      }
      return userInput
}