import React, {Component, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'

import dateFormat, {masks} from 'dateformat'
import {
  getUserName,
  getLink,
  getOthers,
  getTarget,
  getActivity,
  getText,
} from '../../helperfunctions/NotificationHelpers'
import NotificationService from '../../services/notifications/NotificationService'
import { DateTime } from "luxon";

import './NotificationModal.scss'

const NotificationModal = (props) => {
  const [notifications, setNotifications] = useState([])
  const {setBadgeNum} = props // notification count on notification badge on icon

  useEffect(() => {
    NotificationService.listenNotifications({setNotifications, setBadgeNum, resetBadgeNum: false})
  }, [])
  useEffect(() => {
    NotificationService.listenNotificationsAnon({setNotifications, setBadgeNum, resetBadgeNum: false})
  }, [])



  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px hover-notifications notification'
      data-kt-menu='true'
    >
      <div className='d-flex flex-column rounded-top notification-border'>
        <div className='fs-4 px-9 mt-8 mb-4 header-text'>Notifications</div>
      </div>
      <div className='scroll-y mh-325px mb-10'>
        {notifications && notifications.map((notification, index) => (
          // className={` ${!notification.details.clicked ? 'higlight' : ''} // TODO some type of highlight
          <div key={`alert${notification._id}`} className={`d-flex flex-stack py-3 px-9 notification-border ${!notification.details.clicked  ? 'highlight' : ''}`}>
            <div className='d-flex align-items-center'>
              <Link
                to={getLink(notification.details)}
                className='mb-0 me-2'
                onClick={() =>
                  NotificationService.clickNotification({
                    notificationId: notification.details._id,
                    setNotifications,
                    setBadgeNum,
                    resetBadgeNum: false,
                  })
                }
              >
                <div className='fs-6 text-hover-primary fw-bolder title-text'>
                  {getUserName(notification.details)} {getOthers(notification)}{' '}
                  {getActivity(notification.details)} {getTarget(notification.details)}
                </div>
                <div className='fs-8 mb-2 time-text'>
                  {DateTime.fromISO(notification.details.date_time).toRelative()}
                </div>

                <div className='fs-7 description-text'>
                  {getText(notification.details) ? `  ${getText(notification.details)}` : ''}
                </div>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default NotificationModal
