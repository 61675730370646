/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useContext, useCallback, useRef} from 'react'

// import {IProfileDetails, profileDetailsInitValues as initialValues} from '../../../modules/profile/components/settings/SettingsModel'
import {useHistory} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'

import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

import Modal from 'react-modal'

import {UserContext} from '../../contexts/usercontext'

import FeatherIcon from 'feather-icons-react'

import SelectField from '../../modules/auth/reusable/SelectField'

import POLITICIAN_SERIVCE from '../../services/politician/PoliticianService'
import {ProfileContext} from '../../contexts/profilecontext'
import AuthService from '../../services/auth/AuthService'
import VOTER_SERVICE from '../../services/voter/VoterService'
import {ModalHeader} from './ModalHeader'

import './AddVideoModal.scss'
import './CivikModal.scss'

import {FilePond, registerPlugin} from 'react-filepond'
// Import FilePond styles
import 'filepond/dist/filepond.min.css'

import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/storage'

import TextField from '../../modules/auth/reusable/TextField'

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview)

const cityOptions = [
  {label: 'Select City', value: ''},
  {label: 'Toronto', value: 'Toronto'},
  {label: 'Mississauga', value: 'Mississauga'},
]

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '12px',
    padding: '0px',
    width: '94%',
    maxWidth: 520,
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
    zIndex: '9999999999',
  },
}

const initialValues = {
  url: '',
}

const formSchema = Yup.object().shape({
  url: Yup.string().url().required('Video url is required'),
})

export function AddVideoModal(props) {
  const [loading, setLoading] = useState(false)


  const resetModal = () => {
    try {
      Object.keys(formik.values).forEach((key) => {
        formik.values[key] = ''
      })

      Object.keys(formik.touched).forEach((key) => {
        formik.touched[key] = false
      })
    } catch (error) {
      console.log(error)
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema: formSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(async () => {
        props.setPostVideoURL(values.url)
        props.onRequestClose()

        setLoading(false)
      }, 1000)
    },
  })

  useEffect(() => {
    resetModal()
  }, [props.onRequestClose])

  useEffect(() => {
    // Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
    Modal.setAppElement('#CreatePostModal')
  }, [])

  return (
    <Modal
      ariaHideApp={false}
      isOpen={props.isOpen}
      onAfterOpen={props.onAfterOpen}
      onRequestClose={props.onRequestClose}
      style={customStyles}
      contentLabel='Example Modal'
    >
      <form className='AddVideoModal' onSubmit={formik.handleSubmit} noValidate>
        {/* HEADER */}
        <ModalHeader title={'Add Video Link'} onRequestClose={props.onRequestClose} />

        {/* BODY */}

        {/* ROW 1 */}
        <div className='addVideoModal__urlInput'>
          <TextField
            formik={formik}
            type='url'
            field='url'
            placeholder='URL:  Ex: https://youtu.be/L8Qg1uKS-3k'
            isRequired={true}
            noLabel={true}
          />
        </div>

        {/* FOOTER */}
        <div className='civikModal__footer'>
          <button
            type='button'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-active-light-primary btn btn-light  '
            style={{maxWidth: 110}}
            onClick={props.onRequestClose}
          >
            <span className='indicator-label fw-bolder'>Back</span>
          </button>
          <button
            type='submit'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-primary  '
            style={{marginLeft: 20, maxWidth: '110'}}
          >
            {!loading && <span className='indicator-label fw-bolder'>Add</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </Modal>
  )
}
