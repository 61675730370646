/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useContext, useCallback, useRef} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'

import {toAbsoluteUrl} from '../../_metronic/helpers'

import FeatherIcon from 'feather-icons-react'

import {ProfileContext} from '../contexts/profilecontext'
import {FeedLocationContext} from '../contexts/feedLocationContext'

import VOTER_SERVICE from '../services/voter/VoterService'
import FEED_SERVICE from '../services/feed/FeedService'

import ROUTE_PATHS from '../routing/RoutePaths'

// import microtip from 'microtip/microtip.css'
// import microtip from 'microtip/microtip.css'
import {useHistory} from 'react-router-dom'
import './Feed.scss'

import {CreatePostModalWrapper} from './modals/CreatePostModalWrapper'
import {FeedPostContext} from '../contexts/feedPostContext'
import LETTER_COLORS from '../constants/LetterColors'
import PostItem from '../modules/feed/posts/PostItem'
import {CreatePostContext} from '../contexts/createPostContext'
import {GlobalPrivateModalContext} from '../contexts/GlobalModalContext'
import MODAL_NAMES from '../constants/ModalNames'
import {parseFeedFilters} from '../helperfunctions/FeedHelpers'
import UserPicture from '../modules/reusable/UserPicture'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '12px',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
  },
}

const initialFilterOptions = [
  {label: 'All', value: 'All', communityLevel: 'All'},
  {label: 'World', value: 'World', communityLevel: 'International'},
  {label: 'Canada', value: 'Canada', communityLevel: 'Federal'},
]

export function Feed(props) {
  const history = useHistory()
  const [modalIsOpen, setIsOpen] = React.useState(false)
  const [postType, setPostType] = React.useState('Basic Post')
  const PROFILE_CONTEXT = useContext(ProfileContext)

  const CREATE_POST_CONTEXT = useContext(CreatePostContext)
  const FEED_POSTS_CONTEXT = useContext(FeedPostContext)
  const [feedLoading, setFeedLoading] = useState(false)

  const {setIsOpenGlobalModal} = useContext(GlobalPrivateModalContext)
  const setIsOpenCompleteProfile = (o) => setIsOpenGlobalModal(MODAL_NAMES.COMPLETE_PROFILE, o)
  const userBasicProfileComplete = PROFILE_CONTEXT.profile?.basicProfileComplete

  const [postFilter, setPostFilter] = useState('Filter By')
  const [openFilterDropdown, setOpenFilterDropdown] = useState(false)
  const filterOpRef = useRef(null)

  const {feedLocation} = useContext(FeedLocationContext)
  const [filterOptions, setFilterOptions] = useState([...initialFilterOptions])

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00'
  }

  function onAfterCompleteModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00'
  }

  function closeModal() {
    // setIsOpen(false)
    CREATE_POST_CONTEXT.setShowModal({visible: false, postType: 'Basic Post'})
  }

  const getFeed = async (feedFilter) => {
    try {
      // fetch feed
      setFeedLoading(true)
      let userInput = parseFeedFilters(feedFilter, feedLocation)

      console.log('fetching feed ... for: ', userInput.city + ' ' + userInput.region)

      let response = await FEED_SERVICE.getPosts(userInput)
      if (response) {
        FEED_POSTS_CONTEXT.setFeedPosts(response)
      } else {
        FEED_POSTS_CONTEXT.setFeedPosts([])
      }
      setFeedLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (openFilterDropdown && filterOpRef.current && !filterOpRef.current.contains(e.target)) {
        setOpenFilterDropdown(false)
      }
    }

    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [openFilterDropdown])

  useEffect(() => {
    if (feedLocation.city && feedLocation.region)
      setFilterOptions([
        ...initialFilterOptions,
        {label: feedLocation.region, value: feedLocation.region, communityLevel: 'Provincial'},
        {label: feedLocation.city, value: feedLocation.city, communityLevel: 'Municipal'},
      ])
    // TODO: CHECK if the there was a new post added (from others) recently to the feed if so, fetch again
    console.log('FETCHING THE FEED _________')
    // reset feed filter on page load and whenever feedLocation changes
    setPostFilter('All')
    // fetch feed on page load and whenever feedLocation changes
    getFeed({label: 'All', value: 'All', communityLevel: 'All'})
  }, [feedLocation])

  return (
    <div className='Feed' id='Feed' style={{width: '100%'}}>
      <CreatePostModalWrapper
        isOpen={CREATE_POST_CONTEXT.showModal.visible}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel='Example Modal'
        postType={CREATE_POST_CONTEXT.showModal.postType}
        setPostType={(newPostType) => {
          CREATE_POST_CONTEXT.setShowModal({visible: true, postType: newPostType})
        }}
        updateFeed={(newPost) => {
          setFeedLoading(true)
          console.log('newPost', newPost)
          if (newPost._id) {
            FEED_POSTS_CONTEXT.setFeedPosts((feedPosts) => [newPost, ...feedPosts])
          }
          setFeedLoading(false)
        }}
      />

      {/* Create Post */}
      <div className='feed__bar'>
        {/* <div className='feed__barPic'></div> */}
        <div className='feed__barTopWrap'>
          <UserPicture profile={PROFILE_CONTEXT.profile} isReply={false} />
          <div
            className='feed__barBtn'
            onClick={() => {
              if (!userBasicProfileComplete) setIsOpenCompleteProfile(true)
              else {
                CREATE_POST_CONTEXT.setShowModal({visible: true, postType: 'Basic Post'})
              }
            }}
          >
            <span className='feed__barBtnTxt'>Share your thoughts?</span>
          </div>
        </div>

        <div className='feed__barIconsWrapper'>
          <div
            className='feed__barIcon feed__barIconBtn'
            onClick={() => {
              if (!userBasicProfileComplete) setIsOpenCompleteProfile(true)
              else {
                CREATE_POST_CONTEXT.setShowModal({visible: true, postType: 'Basic Post'})
              }
            }}
          >
            <img
              className='navbar__searchIcon'
              src={toAbsoluteUrl('/media/design/my_posts_icon.svg')}
            />
            <span className='feed__barIconTxt'>Create Post</span>
          </div>

          <div
            className='feed__barIcon  feed__barIconBtn'
            onClick={() => {
              if (!userBasicProfileComplete) setIsOpenCompleteProfile(true)
              else {
                CREATE_POST_CONTEXT.setShowModal({visible: true, postType: 'Share News'})
              }
            }}
          >
            <img
              className='navbar__searchIcon'
              src={toAbsoluteUrl('/media/design/share_news_icon.svg')}
            />
            <span className='feed__barIconTxt'>Share News</span>
          </div>
        </div>
      </div>
      {/* Create Post End */}

      {/* FILTER BAR */}
      <div className='feed__postFilterBar'>
        <div
          className='feed__postFilterDropdown'
          onClick={() => {
            !userBasicProfileComplete ? setIsOpenCompleteProfile(true) : setOpenFilterDropdown(true)
          }}
        >
          <span className='feed__postFilterDropdownTxt'>{postFilter}</span>
          <FeatherIcon
            className='feed__postFilterDropdownChevron'
            icon={'chevron-down'}
            width='13'
          />
        </div>

        {openFilterDropdown && (
          <div className='navbar__dropdown feed__postFilterOptions' ref={filterOpRef}>
            <div className='civik-flex-column dropdown__txtWrap'>
              <div className='civik-flex-column ' onClick={() => {}}>
                {filterOptions.map((topic, index) => {
                  return (
                    <div
                      key={index}
                      className='dropdown__iconOptionWrap '
                      onClick={() => {
                        setPostFilter(topic.value)
                        setOpenFilterDropdown(false)
                        getFeed(topic)
                      }}
                    >
                      <span className='feed__postFilterOptionsTxt'>{topic.label}</span>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        )}
      </div>
      {/* Filter Bar end */}

      {/* POSTS */}
      <div className='feed__postsContainer'>
        {feedLoading ? (
          <div className='feed_loadingContainer'>
            <img className='feed__loader' src={toAbsoluteUrl('/media/design/feed_loader.svg')} />
          </div>
        ) : (
          <div className='feed__postsContainerWrap'>
            {FEED_POSTS_CONTEXT.feedPosts.map((feed, index) => {
              return <PostItem key={feed._id} feed={feed} index={index} isSinglePost={false} />
            })}
          </div>
        )}
      </div>
    </div>
  )
}
