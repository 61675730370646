import React, {useEffect, useState, useContext} from 'react'
import AUTH_SERVICE from '../../../services/auth/AuthService'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {ProfileContext} from '../../../contexts/profilecontext'
import ROUTE_PATHS from '../../../../app/routing/RoutePaths'
import {Link, useHistory} from 'react-router-dom'

import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'

import FeatherIcon from 'feather-icons-react'

import './AccountVerifyLink.scss'
import AuthService from '../../../services/auth/AuthService'

export function AccountVerifyLink({error = null, status = null}) {
  const PROFILE_CONTEXT = useContext(ProfileContext)

  // account verify state
  const [emailSent, setEmailSent] = useState(true)

  const [loading, setLoading] = useState(false)

  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const history = useHistory()

  const logout = () => {
    PROFILE_CONTEXT.setProfile({})
    firebase
      .auth()
      .signOut()
      .then(() => console.log('User signed out!'))
    history.push(ROUTE_PATHS.LOGIN)
  }

  const handleResendLink = async () => {
    try {
      let response = await AUTH_SERVICE.sendVerificationEmail()
      if (response?.code == 200) {
        console.log('verify email sent')
        setHasErrors(false)
      }
      if (response.code == 400) {
        setHasErrors(true)
      }
    } catch (error) {
      console.log(error)
    }
  }

  if (emailSent) {
    return (
      <>
        <div
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          // noValidate
          id='kt_login_password_reset_form'
          // onSubmit={formik.handleSubmit}
        >
          <div className='text-center mb-10'>
            {/* begin::Title */}
            {hasErrors == true && (
              <div className='mb-lg-15 alert alert-danger mb-10'>
                <div className='alert-text font-weight-bold'>Error sending verification email.</div>
              </div>
            )}
            {hasErrors == false && (
              <div className='mb-10 bg-light-info p-8 rounded'>
                <div className='text-info'>Resent verify email! Please check your inbox.</div>
              </div>
            )}

            {/* begin::Title */}
            <h1 className='text-dark mb-3'>Join The community!</h1>
            {/* end::Title */}

            {/* begin::Link */}
            <div className=' fw-bold' style={{fontSize: 15, color: '#7E8299'}}>
              Please follow the link to verify your email.
            </div>
            {/* end::Link */}
          </div>

          <div>
            <div className='civik-acc-verify-banner'>
              <div className='civik-flex-row'>
                <img
                  alt='Logo'
                  src={toAbsoluteUrl('/media/design/feather-mail.svg')}
                  className='feather-mail-icon'
                />
                <span>Check your inbox</span>
              </div>
            </div>
            {status && (
              <div
                className='mb-lg-15 alert alert-success'
                style={{maxWidth: 300, overflow: 'wrap'}}
              >
                <div className='alert-text font-weight-bold'>{status}</div>
              </div>
            )}
            {error && (
              <div
                className='mb-lg-15 alert alert-danger'
                style={{maxWidth: 300, overflow: 'wrap'}}
              >
                <div className='alert-text font-weight-bold'>{error}</div>
              </div>
            )}
          </div>
          <div className='civik-verify-resend-link'>
            <span className='civik-cancel-reset-pass' style={{fontWeight: 500}}>
              Didn’t receive an email?{' '}
            </span>
            <span
              className='civik-cancel-reset-pass'
              style={{color: '#00A3FF', cursor: 'pointer'}}
              onClick={handleResendLink}
            >
              Resend
            </span>
          </div>

          {/* end::Form group */}
          <div className='verifyAccount__logoutBtnWrap'>
            <FeatherIcon className='verifyAccount__logoutBtnIcon' icon={'log-out'} color='#000' />
            <a onClick={logout} className='verifyAccount__logoutBtn'>
              Logout
            </a>
          </div>
        </div>
      </>
    )
  }

  return <div>Loading ...</div>
}
