import React, { Component } from 'react'
import clsx from 'clsx'

import './TextField.scss'

class TextField extends Component {
  constructor(props) {
    super(props)
  }

  checkType(type) {
    if (type == 'email') {
      return 'email'
    } else if (type == 'password') {
      return 'password'
    } else {
      return 'text'
    }
  }


  render() {
    const { formik, type, field, placeholder, label, isRequired, noLabel } = this.props

    return (
      <div>
        {!noLabel &&
          <label className={isRequired ? 'form-label fs-6 fw-bold text-dark required' : 'form-label fs-6 fw-bold text-dark'}>{label}</label>
        }

        <input
          placeholder={placeholder}
          {...formik.getFieldProps(field)}
          className={clsx(
            'TextField',
            { 'is-invalid': formik.touched[field] && formik.errors[field] },
            {
              'is-valid': formik.touched[field] && !formik.errors[field],
            }
          )}
          type={this.checkType(type)}
          name={field}
          autoComplete='off'
        />
        {formik.touched[field] && formik.errors[field] && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors[field]}</span>
          </div>
        )}
      </div>
    )
  }
}


export default TextField
